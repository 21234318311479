import "../styles/Terms.css";

function Terms() {
  return (
    <div className="jazz_discount_outer">
      <h2>Terms & Conditions</h2>
      <div style={{ background: "rgba(0,0,0,0.8)", padding: "20px" }}>
        <div>
          <p>
            <b>Terms of the Gamebox Premier League Service Use</b>
          </p>
          <p>
          These Terms and Conditions govern your use of the service at http://tpk.gplclub.mobi/ according
to the subscription scheme, which is provided to Telenor Pakistan subscribers using the Telenor
Pakistan network (hereinafter, the "Service").
          </p>

          <p>
            <b>Service Subscription and unsubscription:</b>
          </p>

 <p>a. Activating the Service: Confirm activation at http://tpk.gplclub.mobi/ and other advertising links as promoted by GPL</p> 
 <p>b. Depends on the subscription duration subscribers will be charged upto Rs. 50 per week or Rs 12/day from their mobile balance.</p> 
 <p>c. If the subscriber's account is successfully charged for the service fee for 7 days, the subscriber is provided access to the service to play and win tournaments 1 day from the payment date.</p> 
 <p>d. To cancel the service, please visit your Account.</p> 
 <p>e. GPL service can also be cancelled by giving a missed call on 02137188888 from your Telenor GPL subscribed mobile number.</p> 
 <p>f. Upon subscription, user agrees to service recurring charges, auto-renewal until unsubscription & discounted pricing from mobile balance.</p> 
 <p>g. Service subscription & unsubscription notification sms for services subscribed & unsubscribed after 9pm, will be sent accordingly.</p> 
 <p>h. Service renewal SMS notifications for subscribed services may be sent anytime during 24hours.</p> 
 <p>i. The Telenor number for subscription maybe shared for the explicit purpose of solving customer issues and product improvement.</p> 
 <p>Conditions of downloading content when using the Service:</p> 
 <p>Content can only be downloaded by Telenor Pakistan customers. There are free tournaments</p> 
 <p>and paid tournaments which are to be played with an entry fee in terms of coins.</p> 
 <p>Conditions for providing information to Service users:</p> 
 <p>During Service activation, the subscriber consents to receiving text messages for new services.</p> 
 <p>Text messages are sent from Telenor Pakistan which does not incur any charges.</p> 
 <p>Service subscribing & unsubscribing via Easy Paisa:</p> 
 <p>1. Activating the Service: Confirm activation at http://tpk.gplclub.mobi/ and other advertising links as promoted by GPL.</p> 
 <p>2. Depends on the subscription duration subscribers will be charged upto Rs. 50 per week or Rs 12/day from their easy Paisa wallet.</p> 
 <p>3. If the subscriber is successfully charged from his easy paisa account (Wallet), he gets service for 7 days, the subscriber is provided access to the service to play and win tournaments 1 day from the payment date.</p> 
 <p>4. To cancel the service, please visit your Account.</p> 
 <p>5. GPL service can also be cancelled by giving a missed call on 02137188888 from your Telenor GPL subscribed mobile number.</p> 
 <p>6. Upon subscription, user agrees to service recurring charges, auto-renewal until unsubscription & discounted pricing from Easy Paisa mobile wallet.</p> 
 <p>7. Service subscription & unsubscription notification sms for services subscribed & unsubscribed after 9pm, will be sent accordingly.</p> 
 <p>8. Service renewal SMS notifications for subscribed services may be sent anytime during 24hours.</p> 
 <p>9. The Telenor number for subscription maybe shared for the explicit purpose of solving customer issues and product improvement. Promotion campaign for Eid Ul Adha festival</p> 
 <p>3.1 The duration of the campaign will be between Jun 10-24 (all dates inclusive)</p> 
 <p>3.2 Clients who have subscribed to Gamebox Premier League and participated during the campaign period will qualify to participate in the campaign</p> 
 <p>3.3 Qualified participants shall be continuously subscribed to Gamebox Premier League during the campaign period.</p> 
 <p>3.4 If subscriber is among the top 25 winners, he or she will be eligible to win the prizes:</p> 

 <table>
                    <tbody><tr>
                        <th>Rank</th>
                        <th>Prize Type</th>
                        <th>Total Amount</th>
                        <th>Players</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Top Up</td>
                        <td>2000</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Top Up</td>
                        <td>1500</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>3-5</td>
                        <td>Top Up</td>
                        <td>1000</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>6-10</td>
                        <td>Top Up</td>
                        <td>400</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>11-25</td>
                        <td>Top Up</td>
                        <td>100</td>
                        <td>15</td>
                    </tr>
                    <tr>
                        <td colSpan={3}>Total</td>
                        <td>25</td>
                    </tr>

                </tbody></table>

<p>3.6 At its own discretion, the operator reserves the right to award the selected winners should there be any discrepancies and make changes to the prizes without notice.</p> 
<p>3.7 Participants understand that prizes awarded are not exchangeable for cash or otherwise.</p> 
<p>3.8 Operator reserves the right to modify or change, at any given time and without any previous notice, the current terms and conditions of use.</p> 

        </div>
      </div>
    </div>
  );
}

export default Terms;
